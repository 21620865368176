import React, { useEffect, useState, forwardRef } from 'react';
import BaseLink, { LinkProps as BaseLinkProps } from 'sensortower-components/src/base-components/Link';

export interface LinkProps extends BaseLinkProps {
  addSourcePage?: boolean;
}

const getHrefWithReferrer = (href: string) => `${href}?referrer=${encodeURIComponent(window.location.href)}`;

const Link = forwardRef(({ addSourcePage, ...props }: LinkProps, ref) => {
  const [href, setHref] = useState(props.href);

  useEffect(() => {
    if (addSourcePage) {
      setHref(getHrefWithReferrer(props.href as string));
    }
  }, []);

  return <BaseLink {...props} href={href} ref={ref}/>;
});

export default Link;
